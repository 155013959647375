<template>
    <div v-if="suggestions.length" class="suggestions-list">
        <suggestion-item
            v-for="(suggestion, index) in suggestions"
            :key="index"
            :suggestion-type="suggestionType"
            :suggestion="suggestion"
            @selected-suggestion="(suggestionName) => $emit('selected-suggestion', suggestionName)"
        />
    </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
    name: "SuggestionsList",
    components: {
        SuggestionItem: () => import(/* webpackChunkName: "suggestion-item" */ "./suggestion-item")
    },
    props: {
        suggestionString: {
            type: String,
            default: ""
        },
        suggestionType: {
            type: String,
            default: "mentions"
        }
    },
    data() {
        return {
            suggestions: ""
        };
    },
    watch: {
        suggestionString: {
            handler() {
                this.asyncFind(this.suggestionString, this.suggestionType);
            }
        }
    },
    created() {
        this.asyncFind = debounce(this.searchSuggestion, 250);
        this.asyncFind(this.suggestionString, this.suggestionType);
    },
    methods: {
        searchSuggestion(suggestionString, suggestionType) {
            if (suggestionString == "") {
                return;
            }

            suggestionString = suggestionString.replace("#", "");
            axios.get(`/search/${ suggestionString.split(" ").pop() }/suggestion?index=${ suggestionType }`).then(({ data }) => {
                const suggestions = data.results.documents.map(tag => {
                    return { name: tag.suggestion }
                })
                this.suggestions = suggestions;
            }).catch((error) => {
                console.log(error.response.data.message);
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.suggestions-list {
    background-color: #1a1a1c;
    border: 1px solid rgba(84, 84, 88, 0.65);
    position: absolute;
    bottom: 0;
    max-height: 200px;
    overflow-y: scroll;
    padding: 0;
    width: 100%;
    z-index: 9999;
}
</style>
